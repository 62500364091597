import styled from 'styled-components';

export const ButtonContainer = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	border-radius: ${(props) => props.borderRadius};
	background-color: ${(props) =>
		props.active ? props.activeColor : props.backgroundcolor};
	border: ${(props) =>
		props.borderColor ? `2px solid ${props.borderColor}` : 'none'};
	font-size: 14px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	color: ${(props) =>
		props.color ? props.color : props.theme.colors.mainPageColorWhite};
	cursor: pointer;

	:hover {
		background-color: ${(props) => props.activeColor};
		color: ${(props) => props.activeColorFont};
	}
`;
