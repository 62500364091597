// LoadingSpinner.js
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import Logo from 'assets/img/logo.png'; // Adjust the path to your logo
import theme from 'theme';

const SpinnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh; // Full height of the viewport
	background-color: #000; // Optional: background color
`;

const LogoImage = styled.img`
	width: 150px; // Adjust the size of your logo
	margin-bottom: 20px; // Space between logo and spinner
`;

const override = `
  display: block;
  margin: 0 auto;
  border-color: #E68A47;
`;

const LoadingSpinner = ({ loading }) => {
	return (
		<SpinnerContainer>
			<LogoImage
				src={Logo}
				alt='Logo'
			/>
			<ClipLoader
				css={override}
				size={150}
				color={theme.colors.mainYellow}
				loading={loading}
			/>
		</SpinnerContainer>
	);
};

export default LoadingSpinner;
