import React, { useEffect, useRef, useState } from 'react';
import {
	WholeContainer,
	Text,
	Title,
	TitleSpan,
	TextContainer,
	TextWidth,
	ButtonContainer,
	ImageLandingPage,
	StatisticContainer,
	StatisticTextContainer,
	StatisticNumberText,
	StatisticText,
	StatisticDescriptionText,
	StatisticDescriptionTextWidth,
	StatisticDescriptionContainer,
	BoldedName,
	Video,
	WorkedWIthContainer,
	LogosContainerWrapper,
	LogosContainer,
	TitleOurServices,
	OurServicesContainer,
	PortfolioButtonContainer,
	Image,
	HomeImage,
	ImageOverlayContainer,
	StyledImageLandingPage,
} from './style';
import Button from 'components/Button/Button';
import LandingPageImage from 'assets/img/landingPageImage.jpg';
import LoopVideoPC from 'assets/video/LandingPageGif.mp4';
import Slider from 'react-slick';
import image1 from 'assets/img/1.png';
import image2 from 'assets/img/2.png';
import image3 from 'assets/img/3.png';
import image4 from 'assets/img/4.png';
import image5 from 'assets/img/5.png';
import image6 from 'assets/img/6.png';
import image7 from 'assets/img/7.png';
import { RequestedCardItems } from 'components';
import LyricVideoGif from 'assets/video/Lyric_Video_Service_PC.mp4';
import AnimatedVideo from 'assets/video/3d_animated_music_video_PC.mp4';
import LoopVideoMobile from 'assets/video/LandingPageGifMobile.mp4';
import LyricVideoGifMobile from 'assets/video/Lyric_Video_Service_Mobile.mp4';
import AnimatedVideoMobile from 'assets/video/3d_animated_music_video_Mobile.mp4';
import { NewItem } from 'components/Header/style';
import { useNavigate } from 'react-router-dom';
import homePageImage from 'assets/img/homePage.png';
import statisticimage from 'assets/img/statisticImage.png';
import { useCounter } from 'hooks/counterAnimation';
import {
	ControlButton,
	ControlsHeader,
	NewItemLink,
	Overlay,
	PaginationButton,
	PaginationContainer,
	PortfolioText,
	PortfolioTitle,
	VideoGrid,
	VideoItem,
	VideoPlayerWrapper,
	VideoThumbnail,
	VideoTitle,
	YellowLine,
} from 'components/Portfolio/style';
import ReactPlayer from 'react-player';
import axios from 'axios';
import Portfolio from 'components/Portfolio/Portfolio';

const API_KEY = 'AIzaSyA6x-SPUd8ptXfvwX6sxNeevTbwkRB0wB4';
const videoList = [
	{ key: 'https://youtu.be/10NWkm-g59c', title: 'Video 1' },
	'https://youtu.be/vUBnzNSG2J0',
	'https://youtu.be/G2pW0b9PX3o',
	'https://youtu.be/7twEIlCP3K0',
	'https://youtu.be/I8PE_stJB9w',
	'https://youtu.be/CKAyWWnwGZI',
	'https://youtu.be/15HzyiMTrD8',
	'https://youtu.be/wNJghTMWKyc',
	'https://youtu.be/Va9tCEUB_As',
	'https://youtu.be/zdKjFZbWhPQ',
];

function HomeContainer() {
	const [videoUrl, setVideoUrl] = useState(LoopVideoPC);
	const navigate = useNavigate();
	const [zoom, setZoom] = useState(false);

	const [startAnimation, setStartAnimation] = useState(false);
	const statRef = useRef(null);

	const videosCreated = useCounter(1353, 2500, startAnimation);
	const happyClients = useCounter(451, 2000, startAnimation);
	const yearsExperience = useCounter(13, 1300, startAnimation);

	const settings = {
		dots: true, // Disable dot indicators if not needed
		infinite: true, // Enable infinite looping
		slidesToShow: 5, // Show 5 images at a time
		slidesToScroll: 1, // Scroll 1 image at a time
		arrows: true, // Disable arrows
		swipe: true,
		autoplay: true, // Enable auto-play feature
		autoplaySpeed: 1000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3, // Adjust for smaller screens
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2, // Adjust for the smallest screens
					slidesToScroll: 1,
				},
			},
		],
	};
	useEffect(() => {
		const handleScroll = () => {
			// Set zoom true if scrolled more than 50 pixels down
			setZoom(window.scrollY > 50);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		// Function to update the video URL
		const updateVideoUrl = () => {
			if (window.innerWidth < 768) {
				setVideoUrl(LoopVideoMobile); // Use mobile video URL for smaller screens
			} else {
				setVideoUrl(LoopVideoPC); // Use default video URL for larger screens
			}
		};

		// Call once to set initial state
		updateVideoUrl();

		// Add event listener for window resize
		window.addEventListener('resize', updateVideoUrl);

		// Cleanup function to remove the event listener
		return () => window.removeEventListener('resize', updateVideoUrl);
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setStartAnimation(true);
						observer.unobserve(entry.target); // Stop observing after triggering
					}
				});
			},
			{
				root: null,
				rootMargin: '0px',
				threshold: 0.1, // Lower threshold if the element is tall
			}
		);

		if (statRef.current) {
			observer.observe(statRef.current);
		} else {
			console.log('statRef not attached');
		}

		return () => {
			if (observer && statRef.current) {
				observer.disconnect(); // Ensure to clean up properly
			}
		};
	}, []);

	return (
		<WholeContainer id='home'>
			<HomeImage src={homePageImage} />

			<ImageLandingPage
				zoom={zoom}
				src={LandingPageImage}
			></ImageLandingPage>
			<ImageOverlayContainer ref={statRef}>
				<StyledImageLandingPage
					src={statisticimage} // Change this to the image you want to use for the background
					alt='Background Image'
				/>
				<StatisticContainer>
					<StatisticTextContainer>
						<StatisticNumberText>{videosCreated}</StatisticNumberText>
						<StatisticText>Created Videos</StatisticText>
					</StatisticTextContainer>
					<StatisticTextContainer>
						<StatisticNumberText>{happyClients}</StatisticNumberText>
						<StatisticText>Happy Clients</StatisticText>
					</StatisticTextContainer>
					<StatisticTextContainer>
						<StatisticNumberText>{yearsExperience}</StatisticNumberText>
						<StatisticText>Years of experience</StatisticText>
					</StatisticTextContainer>
				</StatisticContainer>
			</ImageOverlayContainer>
			<Portfolio homePage={true} />
			<WorkedWIthContainer>
				<TextWidth>
					<Title>
						NAMES WE WORKED <TitleSpan>WITH</TitleSpan>
					</Title>
				</TextWidth>
			</WorkedWIthContainer>
			<LogosContainerWrapper>
				<LogosContainer>
					<Slider {...settings}>
						<Image
							src={image1}
							alt='Image 1'
						/>
						<Image
							src={image2}
							alt='Image 2'
						/>
						<Image
							src={image3}
							alt='Image 3'
						/>
						<Image
							src={image4}
							alt='Image 4'
						/>
						<Image
							src={image5}
							alt='Image 5'
						/>
						<Image
							src={image6}
							alt='Image 6'
						/>
						<Image
							src={image7}
							alt='Image 7'
						/>
					</Slider>
				</LogosContainer>
			</LogosContainerWrapper>

			<PortfolioButtonContainer>
				<Button
					width='150px'
					height='50px'
					onClick={() => navigate('/portfolio')}
					borderRadius='50px'
				>
					Portfolio
				</Button>
			</PortfolioButtonContainer>
		</WholeContainer>
	);
}

export default HomeContainer;
