import ReactPlayer from 'react-player';
import styled from 'styled-components';

export const Div1 = styled.div`
	border-radius: 0.5rem;
	display: flex;
	width: 50%;
	flex-direction: column;
	gap: 1rem;
	margin: 10px 0px;
	padding-bottom: 15px;
	box-shadow: 5px 5px 10px rgba(255, 255, 0, 0.2);
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const ImageDiv = styled.div`
	height: 312px;
	display: flex;
`;

export const Gif = styled.img`
	width: 100%;
	height: 100%;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
`;

export const TextDiv = styled.div`
	background-color: #fff;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
`;

export const NameDiv = styled.div`
	width: 100%;
	display: flex;
	align-items: left;
	gap: 1rem;
`;

export const NameOfUser = styled.p`
	font-size: 24px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

export const DescrDiv = styled.div`
	width: 100%;
	display: flex;
	padding-top: 6px;
	overflow: auto;
`;

export const DescrOfRequest = styled.p`
	font-size: 18px;
	font-weight: 300;
	text-align: left;
`;

export const ReadMoreDiv = styled.div`
	width: 100%;
	display: flex;
	padding-top: 10px;
`;

export const ReadMore = styled.p`
	color: ${(props) => props.theme.colors.mainYellow};
	font-size: 14px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	border-bottom: 1px solid ${(props) => props.theme.colors.mainYellow};
`;

export const Video = styled(ReactPlayer)`
	width: 100% !important;
	height: 100% !important;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const VideoWrapper = styled.div`
	width: 100%; // Adjust based on your requirement
	height: 100%; // Adjust based on your requirement
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	overflow: hidden; // This is crucial for the border-radius to affect the video

	@media (max-width: 768px) {
		width: 100%;
	}
`;
