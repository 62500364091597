import styled from 'styled-components';

export const FooterContainer = styled.div`
	width: 100%;
	height: 600px;
	margin-top: ${(props) => (props.isPortfolioLink ? '-120' : '40')}px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: black;
	position: relative;
	clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
	@media (max-width: 768px) {
		clip-path: none;
		height: 500px;
	}
`;

export const FooterContentContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	margin-left: 4%;
	margin-right: 4%;
	@media (max-width: 768px) {
		clip-path: none;
		flex-direction: column;
	}
`;

export const FooterBigText = styled.p`
	font-size: 48px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	letter-spacing: 0.64px;
	color: ${(props) => props.theme.colors.mainPageColorWhite};
	@media (max-width: 768px) {
		font-size: 24px;
	}
`;

export const FooterBigTextContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 768px) {
		margin-top: 45px;
	}
`;

export const FooterBigContainer = styled.div`
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

export const FooterSmallText = styled.p`
	font-size: 18px;
	font-weight: 300;
	letter-spacing: 0.18px;
	color: #fff;
	max-width: 556px;
	@media (max-width: 768px) {
		text-align: center;
		padding-top: 20px;
		font-size: 16px;
	}
`;

export const FooterAllRightsText = styled.p`
	font-size: 18px;
	font-weight: 300;
	letter-spacing: 0.18px;
	color: #fff;
	@media (max-width: 768px) {
		text-align: center;
	}
`;

export const FooterAllRightsContainer = styled.div`
	display: flex; /* Use Flexbox */
	flex-direction: column; /* Stack children vertically */
	text-align: center;
	margin-top: -60px;
`;
