import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'App.css';
import { AnimatePresence } from 'framer-motion';
import {
	MainContainer,
	Header,
	AdminCheck,
	UserRequests,
	ContactUs,
	AboutUs,
	LyricServices,
	ScrollToTop,
} from 'components';
import Footer from 'components/Footer/Footer';
import Portfolio from 'components/Portfolio/Portfolio';
import AnimationServices from 'components/3DAnimationService/AnimationService';
import MobileVersionVideo from 'assets/video/mobile_version.mp4';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import LoadingSpinner from 'components/Loader/Loader';

export const Video = styled(ReactPlayer)`
	video {
		width: 100% !important;
		height: 100% !important;
		object-fit: fill !important; // This can stretch the video to fill the container
	}
`;
function App() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Simulate a delay to show the loading spinner
		const timer = setTimeout(() => {
			setLoading(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);
	useEffect(() => {
		// Target the canvas element directly
		const canvas = document.querySelector('#tsparticles canvas');
		if (canvas) {
			// Use setProperty to override styles with !important
			canvas.style.setProperty('position', 'absolute', 'important');
			canvas.style.setProperty('top', 'auto', 'important');
			canvas.style.setProperty('left', 'auto', 'important');
			canvas.style.setProperty('bottom', '0', 'important');
			canvas.style.setProperty('width', '100%', 'important');
			canvas.style.setProperty(
				'height',
				window.innerWidth < 768 ? '500px' : '600px',
				'important'
			);
			// Adjust '600px' as needed to match your FooterContainer height
		}
	}, []);

	if (loading) {
		return <LoadingSpinner loading={loading} />;
	}
	const isMobile = window.innerWidth < 768;
	if (isMobile) {
		return (
			<Video
				url={MobileVersionVideo}
				playing
				loop
				muted
				playsinline={true}
				width='100vw'
				height='100vh'
				config={{
					file: {
						forceVideo: true,
					},
				}}
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					zIndex: -1, // Ensure the video is behind other content
				}}
			/>
		);
	}

	return (
		<AnimatePresence exitBeforeEnter>
			<div className='w-screen h-auto flex flex-col bg-primary'>
				<Header />
				<ScrollToTop />
				<main className='mt-14 md:mt-20 px-4 md:px-16 py-4 w-full'>
					<Routes>
						<Route
							path='/*'
							element={<MainContainer />}
						/>
						<Route
							path='/lyric-services'
							element={<LyricServices />}
						/>
						<Route
							path='/aboutUs'
							element={<AboutUs />}
						/>
						<Route
							path='/portfolio'
							element={<Portfolio />}
						/>
						<Route
							path='/contact'
							element={<ContactUs />}
						/>
						<Route
							path='/animation-services'
							element={<AnimationServices />}
						/>
					</Routes>
				</main>
				<Footer />
			</div>
		</AnimatePresence>
	);
}

export default App;
