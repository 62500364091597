import React, { useEffect, useRef, useState } from 'react';
import {
	WholeContainer,
	ImageAboutUs,
	AboutUsText,
	TopSectionContainer,
	Video,
	FirstImagePage,
	VideoContainer, // New styled component for video
	ImageContainer, // New styled component for images
	OverlayText,
	ImageStrips,
	AboutUsTitle,
	BlackLineAboutUs,
	MainTitle,
	BigNumber,
	OrangeLine,
	AboutUsFirstText,
	CopyRights,
	MiddleSectionContainer,
	SecondImagePage,
	MidleTextBox,
	TitleMidleTextBox,
	OrangeBlackLine,
	TextMidleTextBox,
	ThirdImagePage,
	BottomColor,
	ThirdSectionContainer,
	ThirdImageFirstTextTitle,
	ThirdImageFirstTextDescription,
	ThirdImageFirstTextWrapper,
	FourthImageFirstTextTitle,
	FourthImageFirstTextDescription,
	BigTextRight,
	WhiteLine,
	BigTextRightBottom,
	SmallDescription,
	ContactUsButton,
} from './style';
import {
	PortfolioText,
	PortfolioTitle,
	YellowLine,
} from 'components/Portfolio/style';
import { NewItemLink } from 'components/Header/style';
import Button from 'components/Button/Button';
import { FlexBox } from 'components/ContactUs/style';
import AboutUsImage from 'assets/img/aboutUs.png';
import AboutUsVideo from 'assets/video/aboutUsVideo.mp4';
import FirstImage from 'assets/img/aboutUsPerson.png';
import Zebra from 'assets/img/zebra.jpg';
import aboutUsTeam from 'assets/img/aboutUsTeam.png';
import aboutUsThirdImage from 'assets/img/aboutUsThirdImage.png';
import theme from 'theme';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/Loader/Loader';

function AboutUs() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	return (
		<WholeContainer id='home'>
			{loading && <LoadingSpinner loading={loading} />}
			<TopSectionContainer>
				<ImageContainer
					height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
				>
					<FirstImagePage
						src={FirstImage}
						alt='Our Creative Team'
						height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
					/>
					<AboutUsTitle>About Us</AboutUsTitle>
					<BlackLineAboutUs />
					<MainTitle>WHY DAVIX STUDIO?</MainTitle>
					<CopyRights>©2024</CopyRights>
				</ImageContainer>

				<VideoContainer
					height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
				>
					<Video
						url={AboutUsVideo}
						playing
						loop
						muted
						playsinline={true}
						width={window.innerWidth < 768 ? window.innerWidth : '100%'}
						height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
						config={{
							file: {
								forceVideo: true,
							},
						}}
						onPlay={() => setLoading(false)}
					/>

					<ImageStrips src={Zebra}></ImageStrips>
					<OverlayText>We know what will catch your eye</OverlayText>
					<BigNumber>13+</BigNumber>
					<OrangeLine />
					<AboutUsFirstText>
						Collaborating with Davix Studio provides access to a team of
						seasoned professionals with over 13 years of expertise in crafting
						captivating lyric music videos for artists on a global scale
					</AboutUsFirstText>
				</VideoContainer>
			</TopSectionContainer>
			<MiddleSectionContainer>
				<ImageContainer
					height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
				>
					<FirstImagePage
						src={FirstImage}
						alt='Our Creative Team'
						height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
					/>
					<AboutUsTitle>About Us</AboutUsTitle>
					<BlackLineAboutUs />
					<MainTitle>Meet The Team</MainTitle>
					<CopyRights>©2024</CopyRights>
				</ImageContainer>

				<VideoContainer
					height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
				>
					<SecondImagePage
						src={aboutUsTeam}
						alt='Our Creative Team'
						height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
						width={
							window.innerWidth < 768 ? window.innerWidth : window.innerWidth
						}
					/>
					<MidleTextBox>
						<TitleMidleTextBox>Our Offices</TitleMidleTextBox>
						<OrangeBlackLine></OrangeBlackLine>
						<TextMidleTextBox>
							Our offices are situated in Southeastern Europe, specifically in
							Macedonia. Comprising a team of three seasoned animators and three
							proficient video editors, we boast a collective expertise spanning
							over a decade. Committed to excellence, we prioritize investing in
							the refinement of our craft to deliver exceptional quality
						</TextMidleTextBox>
					</MidleTextBox>
				</VideoContainer>
			</MiddleSectionContainer>
			{window.innerWidth < 768 ? (
				<FlexBox>
					<ImageAboutUs
						src={AboutUsImage}
						width={'359px'}
						height={'250px'}
					/>
					<AboutUsText>
						Welcome to Davix Studio, your premier destination for professional
						video editing services. With a passion for storytelling and a
						commitment to excellence, we transform your raw footage into
						captivating narratives that resonate with your audience. Our team of
						skilled editors brings creativity and technical expertise to every
						project, ensuring that your vision is brought to life with precision
						and flair. Whether you're a business, content creator, or filmmaker,
						we're here to elevate your videos to the next level. Discover the
						power of seamless editing and unleash the full potential of your
						content with Davix Studio.
					</AboutUsText>
				</FlexBox>
			) : (
				<ThirdSectionContainer>
					<ThirdImagePage
						src={aboutUsThirdImage}
						alt='Our Creative Team'
						height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
					/>
					<ThirdImageFirstTextTitle>
						EARLY YEARS AND FONDATION
					</ThirdImageFirstTextTitle>
					<ThirdImageFirstTextWrapper>
						<ThirdImageFirstTextDescription>
							In the bustling cityscape where creativity knows no bounds, Davix
							Studio found its humble beginnings. Fuelled by David's unwavering
							dedication to his craft, the studio took its first steps towards
							excellence. Drawing inspiration from Michelangelo's masterpieces,
							particularly the renowned "David" sculpture, the company adopted
							the name "Davix" as a homage to its founder and the timeless
							artistry that continues to inspire generations.
						</ThirdImageFirstTextDescription>
					</ThirdImageFirstTextWrapper>
					<FourthImageFirstTextTitle>
						GROWTH AND EXPANSION
					</FourthImageFirstTextTitle>
					<FourthImageFirstTextDescription>
						Driven by a relentless pursuit of excellence, Davix Studio
						continually expanded its repertoire, exploring new techniques and
						technologies to push the boundaries of creative expression. From
						intricate animations to stunning visual effects, each project bore
						the hallmark of the studio's dedication to quality and innovation.
					</FourthImageFirstTextDescription>
					<BigTextRight>
						we <span>SCULP</span>
					</BigTextRight>
					<WhiteLine></WhiteLine>
					<BigTextRightBottom>LYRIC videos</BigTextRightBottom>
					<BottomColor />
					<SmallDescription>
						Looking ahead, Davix Studio remains poised to embrace new challenges
						and opportunities, guided by its unwavering commitment to
						craftsmanship, collaboration, and creativity.
					</SmallDescription>
					<ContactUsButton>
						<Button
							width={'150px'}
							height={'50px'}
							borderRadius='55px'
							backgroundcolor={theme.colors.mainPageColorBlack}
							borderColor={theme.colors.white}
							activeColor={theme.colors.white}
							activeColorFont={theme.colors.mainPageColorBlack}
							onClick={() => navigate('/contact')}
						>
							Contact Us
						</Button>
					</ContactUsButton>
				</ThirdSectionContainer>
			)}
		</WholeContainer>
	);
}

export default AboutUs;
