import React, { useState, useEffect } from 'react';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { useStateValue } from 'context/StateProvider';
import { actionType } from 'context/reducer';
import { Item, Title, Description } from './style';

const CartItem = ({ icon, title, description, width }) => {
	return (
		<Item width={width}>
			{icon}
			<Title>{title}</Title>
			<Description>{description}</Description>
		</Item>
	);
};

export default CartItem;
