import React, { useRef, useState, useEffect } from 'react';
import {
	PortfolioTitle,
	WholeContainer,
	YellowLine,
	PortfolioText,
	NewItemLink,
	VideoItem,
	VideoThumbnail,
	PaginationContainer,
	PaginationButton,
	Overlay,
	VideoGrid,
	VideoPlayerWrapper,
	ControlButton,
	ControlsHeader,
	VideoTitle,
	PortfolioContainer,
	PortfolioPageTitle,
	PortfolioBorder,
	PortfolioTitleSpan,
} from './style';
import Button from 'components/Button/Button';
import ReactPlayer from 'react-player';
import axios from 'axios';

const videoList = [
	'https://youtu.be/10NWkm-g59c',
	'https://youtu.be/3ttH2wrrL5w',
	'https://youtu.be/iDD0CYGjGBU',
	'https://youtu.be/wryynQXgLPA',
	'https://youtu.be/gnFA4msrX7M',
	'https://youtu.be/dnNt7L7uHig',
	'https://youtu.be/fYFl3RW3wYE',
	'https://youtu.be/iQ8ZrKFOGdQ',
	'https://youtu.be/MEE1pZlVtUw',
	'https://youtu.be/tzqJ-TogQ-0',
	'https://youtu.be/n0bEzrwa7MQ',
	'https://youtu.be/4U-HRULj24M',
	'https://youtu.be/vUBnzNSG2J0',
	'https://youtu.be/G2pW0b9PX3o',
	'https://youtu.be/GO_5NcbveFM',
	'https://youtu.be/7twEIlCP3K0',
	'https://youtu.be/k54FtqfuCGI',
	'https://youtu.be/I8PE_stJB9w',
	'https://youtu.be/DZ2HOhnb8tQ',
	'https://youtu.be/CKAyWWnwGZI',
	'https://youtu.be/15HzyiMTrD8',
	'https://youtu.be/EWmUJ13zrUY',
	'https://youtu.be/wNJghTMWKyc',
	'https://youtu.be/Va9tCEUB_As',
	'https://youtu.be/hcqIZ9fCxVk',
	'https://youtu.be/zdKjFZbWhPQ',
	'https://youtu.be/IYGb-B2mCE0',
	'https://youtu.be/yMMki8Jud44',
	'https://youtu.be/_mnD7-sXrRAc',
	'https://youtu.be/1m3Ha5JQwkQ',
	'https://youtu.be/zKCAKG_UCx4',
	'https://youtu.be/CMQ0vriOMkA',
	'https://youtu.be/hJEkpIuRQ1M',
	'https://youtu.be/RraeXtAfbRs',
	'https://youtu.be/vlQzdsvDwMY',
	'https://youtu.be/Xc5187LCxNU',
	'https://youtu.be/7xFz5PONgvM',
	'https://youtu.be/9bZ31W7mFIQ',
	'https://youtu.be/Y_-v0nONhpA',
	'https://youtu.be/1G176T6oiTo',
	'https://youtu.be/6lXDqPsP7fI',
	'https://youtu.be/zyE1zU6PKrM',
	'https://youtu.be/l9KkPmK_C3s',
	'https://youtu.be/KIXz3ou4uV8',
	'https://youtu.be/Fc5BHKxAFXw',
	'https://youtu.be/FaS-DLPFLy8',
	'https://youtu.be/3iWbSmNzF5s',
	'https://youtu.be/ferXKZSl9ds',
	'https://youtu.be/_astXwWEpLY',
	'https://youtu.be/BvuoViEssXk',
	'https://youtu.be/i4SS6OwK-JM',
	'https://youtu.be/zDtL3nEIDh8',
	'https://youtu.be/DXXRiA16cy4',
	'https://youtu.be/y5LRCI9HsJA',
	'https://youtu.be/TcPnn4eG3eo',
	'https://youtu.be/0aryfXycThc',
];

function Portfolio({ homePage }) {
	const [currentPage, setCurrentPage] = useState(1);
	const [videosPerPage] = useState(4);
	const [showOverlay, setShowOverlay] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState('');
	const [videoDetails, setVideoDetails] = useState([]);

	// Extracts video ID from YouTube URL
	const getVideoId = (url) => {
		// Check if url is a string
		if (typeof url !== 'string') {
			console.error('URL must be a string', url);
			return ''; // Return an empty string or handle this case as needed
		}

		const urlArr = url.split('/');
		const idString = urlArr[urlArr.length - 1];
		return idString.split('?')[0];
	};

	const indexOfLastVideo = currentPage * videosPerPage;
	const indexOfFirstVideo = indexOfLastVideo - videosPerPage;

	const openOverlay = (videoUrl) => {
		setSelectedVideo(getVideoId(videoUrl));
		setShowOverlay(true);
	};

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// Handlers for zoom in, zoom out, and full screen
	const playerRef = useRef(null);

	const chunkArray = (array, chunkSize) => {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}
		return chunks;
	};

	useEffect(() => {
		const fetchVideoTitles = async () => {
			const videoIdBatches = chunkArray(
				videoList.map((url) => getVideoId(url)),
				50
			);
			const results = [];
			for (const batch of videoIdBatches) {
				try {
					const response = await axios.get(
						`https://www.googleapis.com/youtube/v3/videos`,
						{
							params: {
								id: batch.join(','),
								key: process.env.REACT_APP_YOUTUBE_API,
								part: 'snippet',
							},
						}
					);
					results.push(
						...response.data.items.map((item) => ({
							id: item.id,
							title: item.snippet.title,
							thumbnail: item.snippet.thumbnails.high.url,
						}))
					);
				} catch (error) {
					console.error('Error fetching video details:', error);
				}
			}

			setVideoDetails(results);
		};

		fetchVideoTitles();
	}, []);

	return (
		<WholeContainer
			id='home'
			homePage={homePage}
		>
			{homePage && (
				<>
					<PortfolioTitle
						homePage={homePage}
						disableMarginLeft={true}
					>
						Portfolio
					</PortfolioTitle>
					<YellowLine homePage={homePage} />
				</>
			)}

			{!homePage && (
				<PortfolioContainer>
					<PortfolioPageTitle homePage={homePage}>
						<PortfolioTitleSpan>THE PORTFOLIO</PortfolioTitleSpan> FROM DAVIX
						STUDIO.
					</PortfolioPageTitle>
				</PortfolioContainer>
			)}
			{homePage && (
				<PortfolioText homePage={homePage}>Over 1353 created</PortfolioText>
			)}

			{homePage && (
				<NewItemLink to='/portfolio'>
					<Button
						width={'150px'}
						height={'50px'}
						onClick={() => {}}
						homePage={homePage}
						backgroundcolor={'#38D3E5'}
						borderRadius='50px'
						color={'#000'}
					>
						Portfolio
					</Button>
				</NewItemLink>
			)}
			{homePage && (
				<VideoGrid homePage={homePage}>
					{videoDetails
						.slice(indexOfFirstVideo, indexOfLastVideo)
						.map((video, index) => (
							<VideoItem
								key={video.id}
								onClick={() => openOverlay(`https://youtu.be/${video.id}`)}
							>
								<VideoThumbnail src={video.thumbnail} />
								<VideoTitle>{video.title}</VideoTitle>
							</VideoItem>
						))}
				</VideoGrid>
			)}

			{!homePage && ( // Conditionally render pagination only if not on the home page
				<PortfolioBorder>
					<VideoGrid homePage={homePage}>
						{videoDetails
							.slice(indexOfFirstVideo, indexOfLastVideo)
							.map((video, index) => (
								<VideoItem
									key={video.id}
									onClick={() => openOverlay(`https://youtu.be/${video.id}`)}
								>
									<VideoThumbnail src={video.thumbnail} />
									<VideoTitle>{video.title}</VideoTitle>
								</VideoItem>
							))}
					</VideoGrid>
					<PaginationContainer>
						{[...Array(Math.ceil(videoList.length / videosPerPage)).keys()].map(
							(number) => (
								<PaginationButton
									key={number}
									onClick={() => paginate(number + 1)}
								>
									{number + 1}
								</PaginationButton>
							)
						)}
					</PaginationContainer>
				</PortfolioBorder>
			)}
			{showOverlay && (
				<Overlay>
					<VideoPlayerWrapper ref={playerRef}>
						<ControlsHeader>
							<ControlButton onClick={() => setShowOverlay(false)}>
								✕
							</ControlButton>
						</ControlsHeader>
						<ReactPlayer
							ref={playerRef}
							url={`https://www.youtube.com/watch?v=${selectedVideo}`}
							playing
							width='100%'
							height='100%'
						/>
					</VideoPlayerWrapper>
				</Overlay>
			)}
		</WholeContainer>
	);
}

export default Portfolio;
