import styled from 'styled-components';
import { motion } from 'framer-motion';

export const WholeContainer = styled.section`
	padding-top: 150px;
	display: flex;
	gap: 0.5rem;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-width: 768px) {
		flex-direction: column;
		display: flex;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
		align-items: center;
		justify-content: center;
	}
`;
export const ImageDiv = styled.div`
	position: relative;
	height: 100%;
`;

export const Image = styled.img`
	width: 740px !important;
	height: 480px !important;
	border-radius: 5px;
	object-fit: fill;
	z-index: 2;
`;

export const ImageIcons = styled.img`
	width: 150px !important;
	height: 150px !important;
	object-fit: cover;
`;

export const ServiceText = styled.p`
	font-size: 24px;
	font-weight: 300;
	max-width: 986px;
	color: ${(props) => props.theme.colors.grayTextColor};
	margin-bottom: 20px;
	text-align: center;
	background: linear-gradient(to right, black 48%, white 52%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

	@media (max-width: 768px) {
		font-size: 16px;
		width: auto;
	}
`;

export const YellowLine = styled.div`
	width: 740px;
	height: 8px; // Adjust the height to control the thickness of the line
	margin: 20px 0 20px 0;
	margin-left: 50px;
	background-color: ${(props) =>
		props.theme.colors.mainYellow}; // Set the line color to yellow
	@media (max-width: 768px) {
		width: 184px;
	}
`;

export const VideoWrapper = styled.div`
	position: relative;
	overflow: hidden;
	margin-top: 50px;
	width: 90%;

	padding-top: 40.25%; /* Adjust this value to change aspect ratio if needed */
	margin-left: auto;
	margin-right: auto;
	padding-left: 10%;
	padding-right: 10%;
	background-color: #000; /* Background color for sides */
	border-radius: 20px;

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export const OurVisionContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	background-color: black;
	position: relative;
	@media (max-width: 768px) {
		clip-path: none;
		height: 500px;
	}
`;

export const OurVisionTitle = styled.h1`
	color: ${(props) => props.theme.colors.mainYellow};
	margin-top: 40px;
	font-size: 32px;
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

export const OurVisionText = styled.p`
	color: ${(props) => props.theme.colors.mainPageColorWhite};
	margin-top: 40px;
	font-size: 48px;
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	font-weight: 300;
	max-width: 1500px;
	text-align: center;
`;

export const Flexbox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 2rem;
	margin-top: 80px;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

export const FlexBoxCards = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 180px;
	margin: 80px 20px 0px 20px;
	padding-bottom: 50px;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

export const FlexColumnBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	gap: 15px;
	padding: 16px;
	margin: 16px;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

export const FlexBoxTitle = styled.h2`
	color: ${(props) => props.theme.colors.mainPageColorWhite};
	font-size: 36px;
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

export const FlexBoxText = styled.p`
	color: ${(props) => props.theme.colors.mainPageColorWhite};
	font-size: 18px;
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	font-weight: 300;
`;

export const ImagePage = styled.img`
	width: 100%;
	object-fit: cover;
	margin-right: 10px;
	height: ${(props) => props.height}px;
	position: absolute;
	top: 0;
`;

export const TextContainer = styled.div`
	z-index: 10;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
`;

export const WhiteTextSpan = styled.span`
	color: ${(props) => props.theme.colors.mainPageColorWhite};
	padding-left: 15px;
`;

export const BeageContainer = styled.div`
	background-color: #fff9ef;
	margin-top: -39%;
	height: 80vh;
	width: 100%;
`;
