import React, { useState, useEffect } from 'react';
import HomeContainer from 'components/HomeContainer/HomeContainer';
import { useStateValue } from 'context/StateProvider';
import { MainComponent } from './style';
import Footer from 'components/Footer/Footer';

const MainContainer = () => {
	return (
		<MainComponent>
			<HomeContainer />
		</MainComponent>
	);
};

export default MainContainer;
