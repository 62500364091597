import ReactPlayer from 'react-player';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const ButtonOrderNow = styled.button`
	background-image: linear-gradient(to bottom right, #ff7316, #ffa20e);
	width: 100%;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	transition: all ease-in-out 100ms;
	&:hover {
		box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
	}
	@media (min-width: 768px) {
		width: auto;
	}
`;

export const Text = styled.p`
	font-size: 18px;
	color: ${({ theme }) => theme.colors.grayTextColor};
	font-weight: 300;
	text-align: center;
	margin-top: 20px;
	@media (max-width: 768px) {
		font-size: 16px;
		text-align: center;
	}
`;

export const Title = styled.p`
	font-size: 64px;
	font-weight: ${({ theme }) => theme.fontWeight.semibold};
	color: ${({ theme }) => theme.colors.grayTextColor};
	text-align: center;
	@media (max-width: 768px) {
		font-size: 28px;
	}
`;

export const TitleSpan = styled.span`
	font-size: 64px;
	color: ${({ theme }) => theme.colors.mainYellow};
	@media (max-width: 768px) {
		font-size: 28px;
	}
`;

export const WholeContainer = styled.section`
	display: block;
	gap: 0.5rem;
	width: 100%;
	margin-left: 20px;
	margin-top: -40px;
	align-items: center;
	justify-content: center;
`;

export const Image = styled.img`
	width: 150px !important;
	height: 150px !important;
	object-fit: cover;
`;

export const TextContainer = styled.div`
	align-content: center;
	justify-content: center;
	display: flex;
	@media (max-width: 768px) {
		width: 95%;
		margin-right: 20px;
	}
`;

export const PortfolioButtonContainer = styled.div`
	align-content: center;
	justify-content: center;
	display: flex;
	margin-top: 70px;
	@media (max-width: 768px) {
		width: 95%;
		margin-right: 20px;
	}
`;

export const TextWidth = styled.div`
	width: 965px;
`;
export const ButtonContainer = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const ImageLandingPage = styled.img`
	width: 100%;
	margin-left: -10px;
	margin-top: -5px;
	object-fit: cover;
	min-height: 280px;
	height: auto; // Maintain aspect ratio
	transition: transform 2s ease-in-out; // Smooth transition for zoom

	// Apply a transform scale based on the zoom prop
	transform: ${(props) => (props.zoom ? 'scale(1.1)' : 'scale(1)')};
`;

export const StatisticTextContainer = styled.div`
	justify-content: center;
	align-items: center;
	@media (max-width: 768px) {
		margin-top: 50px;
		justify-content: center;
		align-items: center;
	}
`;

export const StatisticNumberText = styled.p`
	font-size: 128px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	color: ${(props) => props.theme.colors.mainPageColorBlack};
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 768px) {
		font-size: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const StatisticText = styled.p`
	font-size: 36px;
	font-weight: 300;
	margin-top: 15px;
	color: ${(props) => props.theme.colors.grayTextColor};
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 768px) {
		font-weight: 300;
		margin-top: 8px;
		font-size: 16px;
	}
`;

export const StatisticDescriptionText = styled.p`
	font-size: 28px;
	font-weight: 300;
	color: ${(props) => props.theme.colors.grayTextColor};
	display: flex;
	justify-content: center;
	text-align: center;
	@media (max-width: 768px) {
		font-weight: 300;
		margin-top: 8px;
		font-size: 16px;
	}
`;

export const StatisticDescriptionTextWidth = styled.div`
	width: 1500px;
	@media (max-width: 768px) {
		max-width: 350px;
	}
`;

export const StatisticDescriptionContainer = styled.div`
	margin-top: 80px;
	display: flex;
	justify-content: center;
	text-align: center;
	margin-bottom: 180px;
	@media (max-width: 768px) {
		width: 100%;
		margin-bottom: 80px;
		margin-left: -10px;
		margin-top: 50px;
		justify-content: center;
		text-align: center;
	}
`;

export const BoldedName = styled.span`
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	display: contents;
`;

export const Video = styled(ReactPlayer)`
	margin-left: -10px;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const WorkedWIthContainer = styled.div`
	align-content: center;
	justify-content: center;
	display: flex;
	margin-top: 60px;
	@media (max-width: 768px) {
		width: 95%;
		margin-right: 20px;
	}
`;

export const LogosContainerWrapper = styled.div`
	margin-top: 40px;
	display: flex;
	width: 100%;
	justify-content: center;
`;
export const LogosContainer = styled.div`
	width: 80%;
`;

export const TitleOurServices = styled.p`
	font-size: 40px;
	font-family: ${(props) => props.theme.fontFamily.montserrat};
`;

export const OurServicesContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-top: 40px;
	margin-right: 40px;
	margin-left: 20px;
	@media (max-width: 768px) {
		margin-top: 10px;
		flex-direction: column;
	}
`;

export const HomeImage = styled.img`
	width: 100%;
	object-fit: cover;
	margin-left: -10px;
`;

export const ImageOverlayContainer = styled.div`
	position: relative;
	width: 100%; // Adjust this based on your layout needs
	height: auto; // Adjust this based on your image height
`;

export const StyledImageLandingPage = styled.img`
	width: 100%;
	height: auto;
	margin-left: -10px;
	margin-top: 19px;
	display: block; // Ensure the image takes up the whole container width
`;

export const StatisticContainer = styled.div`
	position: absolute;
	top: 0; // Align to the top of the container
	left: 0; // Align to the left of the container
	right: 0; // Ensure it stretches across to the right
	width: auto; // Adjust width to be auto to respect padding
	height: 100%; // Cover the entire container height
	padding: 0 10%; // Add padding of 2% on both left and right sides
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center; // Center content vertically and horizontally
	color: white; // Set text color
	text-align: center;
	z-index: 2; // Ensure it's above the image

	@media (max-width: 768px) {
		margin-top: 10px;
		flex-direction: column;
	}
`;
