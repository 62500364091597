import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export const StyledHeader = styled.header`
	position: fixed;
	z-index: 100000;
	width: 100%;
	height: 65px;
	max-width: 90%;
	padding: 3px 4px;
	background: ${(props) =>
		props.filled ? props.theme.colors.mainPageColorBlack : 'transparent'};
	display: flex; /* Use flexbox */
	justify-content: center; /* Center horizontally */
	align-items: center; /* Center vertically */
	top: 0; /* Adjust according to your layout */
	left: 0; /* Adjust according to your layout */
	right: 0; /* Adjust according to your layout */
	margin: 0 auto; /* Centers horizontally */
	border-radius: 55px;
	margin-top: 20px;
	border: 1px solid #ffff;
	@media (min-width: 768px) {
		padding: 6px 16px;
	}
`;
// background-color: var(--primary);
export const DesktopContainer = styled.div`
	display: none;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: space-between;

	@media (min-width: 768px) {
		display: flex;
	}
`;

export const MobileContainer = styled.div`
	display: none; // Hidden by default
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 768px) {
		display: flex;
		align-items: center;
	}
`;

export const LogoLink = styled(NavLink)`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	text-decoration: none;
`;

export const LogoImage = styled.img`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	object-fit: cover;
	margin-left: 10px;
	@media (max-width: 768px) {
	}
`;

export const LogoText = styled.p`
	color: black;
	font-size: 1.25rem;
	font-weight: bold;
`;
//color: var(--headingColor)

export const BigDiv = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const NewItemLink = styled(NavLink)`
	display: flex;
	align-items: center;
	padding-right: 25px;
	font-size: 18px;
	text-decoration: none;

	color: ${(props) => props.theme.colors.mainPageColorHeaderWhite};
	&:focus {
		outline: none;
	}
	&:hover {
		color: ${(props) => props.theme.colors.mainYellow};
	}
	&.active {
		color: ${(props) => props.theme.colors.mainYellow};
	}
`;

export const MobileMenuIcon = styled.div`
	display: none;

	@media (max-width: 750px) {
		display: block;
	}
`;

export const List = styled(motion.ul)`
	display: flex;
	align-items: center;
	gap: 8px;
	list-style-type: none;
`;

export const ListItems = styled.li`
	font-size: 1.2rem;
	color: #2e2e2e;
	margin-right: 8px;
	&:hover {
		color: #2e2e2e;
	}
	transition: all 0.1s ease-in-out;
	cursor: pointer;
`;

export const MotionDivNewItemLogout = styled(motion.div)`
	width: 350px;
	background-color: ${(props) => props.theme.colors.mainPageColorHeaderWhite};
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 66px;
	right: 6px;

	&::before {
		content: '';
		position: absolute;
		top: -12px;
		right: 5px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 12px 8px;
		border-color: transparent transparent #f9fafb transparent;
	}
`;

export const NewItem = styled(NavLink)`
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	color: ${(props) => props.theme.colors.grayTextColor};
	margin: 0.5rem;
	padding: 12px;
	border-radius: 0.375rem;
	text-decoration: none !important;
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 0.75rem;
`;

export const ServicesContainer = styled(motion.div)`
	width: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	background-color: #000;
	${(props) => props.theme.colors.mainPageColorBlack};
	position: absolute;
	left: 0;
	top: 100px;
	z-index: 1000;
	padding: 10px 5px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 1px solid #ffff;
`;

export const ServicesTitle = styled.h2`
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	color: ${(props) => props.theme.colors.grayTextColor};
	margin: 0.5rem;
	padding: 12px;
	text-decoration: none !important;
	display: flex;
	align-items: center;
	font-size: 24px;
	max-width: 391px;
	gap: 0.75rem;
`;

export const Divider = styled.div`
	height: 100px;
	background-color: ${(props) =>
		props.theme.colors.mainYellow}; // Your theme's yellow color
	width: 2px; // Adjust based on your design preference
	margin: 20px 0; // Add some space around the divider
	margin-right: 50px;
`;

export const ServiceLinkContainer = styled.div`
	width: 40%;
	border: 1px solid ${(props) => props.theme.colors.mainYellow}; // Use your theme's yellow color
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: left;
	border-radius: 20px;
	max-width: 556px;
	cursor: pointer;
`;

export const ServiceDescription = styled.p`
	font-size: 14px; // Adjust based on your preference
	color: ${(props) => props.theme.colors.grayTextColor};
	text-align: left;
`;

export const ServiceTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	height: 50px;
`;

export const ServiceTextSeparator = styled.div`
	border-bottom: 2px solid ${(props) => props.theme.colors.mainYellow};
	width: 180px;
`;

export const ServiceTextContainer2 = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px;
	cursor: pointer;
	text-align: center;
	justify-content: center;
	width: 100%;
	height: 50px;
`;

export const ServicesTitle2 = styled.h2`
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	font-family: ${(props) => props.theme.fontFamily.montserrat};
	color: ${(props) => props.theme.colors.white};
	text-decoration: none !important;
	align-items: left;
	font-size: 16px;

	:hover {
		color: ${(props) => props.theme.colors.mainYellow};
	}
`;

export const HeaderLine = styled.div`
	height: 37px;
	width: 4px;
	border-radius: 10px;
	margin: 0 20px;
	margin-right: 35px;
	background-color: #fff;
`;

export const ContactUs = styled.button``;
