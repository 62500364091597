import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import portfolioBackground from 'assets/img/portfolio_background.png';

export const WholeContainer = styled.section`
	background-color: ${(props) => (props.homePage ? 'black' : 'none')};
	padding-top: ${(props) => (props.homePage ? '30px' : '60px')};
	background-image: ${(props) =>
		props.homePage ? 'none' : `url(${portfolioBackground})`};
	background-position: center; // Keep the image centered
	background-repeat: no-repeat; // No repetition of the image
	background-size: cover;
	display: flex;
	gap: 0.5rem;
	margin-left: ${(props) => (props.homePage ? '-10px' : '0')};
	margin-right: ${(props) => (props.homePage ? '-10px' : '0')};
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh; // Ensures at least the viewport height
	@media (max-width: 768px) {
		height: auto; // Adjust height or make it auto to ensure it fits the content
		padding-top: 50px; // Adjust padding as needed
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
		background-size: cover;
		background-position: center;
		min-height: 100vh; // Ensures it also covers at least the viewport height on smaller screens
	}
`;

export const PortfolioTitle = styled.h1`
	font-size: ${(props) => (props.homePage ? 48 : 64)}px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	color: ${(props) =>
		props.homePage
			? props.theme.colors.white
			: props.theme.colors.greyTextColor};
	display: flex;
	justify-content: center;
	z-index: 1000;
	margin-left: ${(props) => (props.disableMarginLeft ? 0 : 50)}px;
	align-items: center;
	@media (max-width: 768px) {
		font-size: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const YellowLine = styled.div`
	width: ${(props) => (props.homePage ? 290 : 386)}px; // 386px;
	height: ${(props) =>
		props.homePage
			? 5
			: 2}px; // Adjust the height to control the thickness of the line
	margin: 20px 0 20px 0;

	background-color: ${(props) =>
		props.homePage
			? '#38D3E5'
			: props.theme.colors.mainYellow}; // Set the line color to yellow
	@media (max-width: 768px) {
		width: 184px;
	}
`;

export const PortfolioText = styled.p`
	font-size: 24px;
	font-weight: 300;
	color: ${(props) =>
		props.homePage
			? props.theme.colors.white
			: props.theme.colors.grayTextColor};
	margin-bottom: 20px;
	text-align: center;
	@media (max-width: 768px) {
		font-size: 16px;
		width: auto;
	}
`;

export const NewItemLink = styled(NavLink)`
	display: flex;
	align-items: center;
	font-size: 18px;
	text-decoration: none;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	color: ${(props) => props.theme.colors.grayTextColor};
	&:focus {
		outline: none;
	}
	&:hover {
		color: ${(props) => props.theme.colors.mainYellow};
	}
	&.active {
		color: ${(props) => props.theme.colors.mainYellow};
	}
`;

export const VideoGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(
		${(props) => (props.homePage ? 2 : 2)},
		1fr
	); // 2 columns if homePage is true, otherwise 3
	grid-gap: 20px;
	justify-items: center;
	width: 100%; // Use full width of the container
	max-width: 1300px;
	margin-top: ${(props) => (props.homePage ? 40 : -50)}px;
	margin-bottom: ${(props) => (props.homePage ? 40 : 0)}px;
`;

export const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`;

export const PaginationButton = styled.button`
	margin: 0 5px;
	background-color: transparent;
	border: none;
	padding: 20px;

	&:hover {
		cursor: pointer;
		background-color: ${(props) => props.theme.colors.grayTextColor};
		color: #fff;
	}
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
`;

export const CloseButton = styled.button`
	margin-top: 10px;
`;

export const VideoPlayerWrapper = styled.div`
	width: 80vw; /* 80% of the viewport width */
	max-width: 1240px; /* Maximum width */
	aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio */
	margin: auto; /* Center it */
`;

export const ControlsHeader = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	background-color: #000; /* Black background */
	padding: 10px;
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	width: 100%; /* Full width */
	z-index: 1100; /* Ensure it's above the player */
`;

export const ControlButton = styled.button`
	background-color: transparent;
	border: none;
	margin-right: 20px;
	border-radius: 5px;
	padding: 5px 10px;
	color: #fff;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
`;

export const VideoThumbnail = styled.div`
	background-image: url(${(props) => props.src});
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%; // Ensure full coverage within the item
	max-width: ${(props) => (props.homePage ? '600px' : '100%')}; // 100%; ;
	transition: transform 0.3s ease-in-out;

	@media (max-width: 768px) {
		width: 90%;
		height: 100%;
	}
`;

export const VideoTitle = styled.span`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(
		0,
		0,
		0,
		0.5
	); // Semi-transparent background for readability
	color: #fff;
	text-align: center;
	padding: 5px;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s ease-in-out; // Smooth transition for fade effect
`;

export const VideoItem = styled.div`
	position: relative;
	cursor: pointer;
	width: 100%; // Take the full width of the grid column
	padding-top: 30%; // Aspect ratio of 16:9 (100/16*9)
	max-width: ${(props) => (props.homePage ? '600px' : '500px')}; // 100%;
	max-height: ${(props) => (props.homePage ? '250px' : '200px')};
	overflow: hidden;
	background-color: #000;
	border-radius: ${(props) => (props.homePage ? '0px' : '30px')};
	&:hover {
		& > div {
			transform: scale(1.1); // Zoom effect on hover
		}
		& > span {
			visibility: visible;
			opacity: 1;
		}
	}

	@media (max-width: 768px) {
		width: 100%; // Full width for smaller devices
		padding-top: 56.25%;
	}
`;

export const PortfolioPageTitle = styled.h1`
	font-size: ${(props) => (props.homePage ? 24 : 36)}px;
	color: ${(props) => props.theme.colors.greyTextColor};
	display: flex;
	justify-content: left;
	font-weight: 400;
	align-items: left;
	@media (max-width: 768px) {
		font-size: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const PortfolioContainer = styled.div`
	min-width: 1150px;
	justify-items: center;
	align-items: center;
	margin-top: -74px;
`;

export const PortfolioBorder = styled.div`
	border: 1px solid ${(props) => props.theme.colors.grayTextColor};
	border-radius: 30px;
	justify-items: center;
	width: 100%; // Use full width of the container
	max-width: 1300px;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
	margin-top: 50px;
`;

export const PortfolioTitleSpan = styled.span`
	background-color: ${(props) => props.theme.colors.mainYellow};
`;
