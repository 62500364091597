import { React, useCallback, useContext, useEffect } from 'react';
import Button from 'components/Button/Button';
import { NewItemLink, PortfolioTitle } from 'components/Portfolio/style';
import {
	ServiceText,
	YellowLine,
	VideoWrapper,
	OurVisionContainer,
	OurVisionTitle,
	WholeContainer,
	OurVisionText,
	Flexbox,
	Image,
	FlexColumnBox,
	FlexBoxTitle,
	FlexBoxText,
	FlexBoxCards,
	ImageIcons,
	ImagePage,
	TextContainer,
	WhiteTextSpan,
	BeageContainer,
} from './style';
import ReactPlayer from 'react-player';
import { TbMessageCircle2 } from 'react-icons/tb';
import { CartItem } from 'components';
import { ThemeContext } from 'styled-components';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { LuCircleDashed } from 'react-icons/lu';
import { loadSlim } from 'tsparticles-slim';
import Particles from 'react-tsparticles';
import {
	LogosContainer,
	LogosContainerWrapper,
	TextWidth,
	Title,
	TitleSpan,
	WorkedWIthContainer,
} from 'components/HomeContainer/style';
import Slider from 'react-slick';
import image1 from 'assets/img/1.png';
import image2 from 'assets/img/2.png';
import image3 from 'assets/img/3.png';
import image4 from 'assets/img/4.png';
import image5 from 'assets/img/5.png';
import image6 from 'assets/img/6.png';
import image7 from 'assets/img/7.png';
import FirstImage from 'assets/img/lyric_video_image.png';
import OurVision from 'assets/img/our_vision.png';
import {
	FirstImagePage,
	ImageContainer,
	TopSectionContainer,
} from 'components/AboutUs/style';
import { BlackBackGround } from 'components/ContactUs/style';

const AnimationServices = () => {
	const theme = useContext(ThemeContext);

	const particlesInit = useCallback(async (engine) => {
		console.log(engine);
		// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		//await loadFull(engine);
		await loadSlim(engine);
	}, []);
	const particlesLoaded = useCallback(async (container) => {}, []);
	const isMobile = window.innerWidth < 768;
	useEffect(() => {
		// Target the canvas element directly
		const canvas = document.querySelector('#tsparticlesvision canvas');
		if (canvas) {
			// Use setProperty to override styles with !important
			canvas.style.setProperty('position', 'absolute', 'important');
			canvas.style.setProperty('z-index', '0', 'important');
			canvas.style.setProperty('top', '0', 'important');
			canvas.style.setProperty('left', '0', 'important');
			canvas.style.setProperty('width', '100%', 'important');
			canvas.style.setProperty(
				'height',
				window.innerWidth < 768 ? '500px' : '1000px',
				'important'
			);
			// Adjust '600px' as needed to match your FooterContainer height
		}
	}, []);

	const cards = [
		{
			icon: (
				<TbMessageCircle2
					color={theme.colors.mainYellow}
					size={32}
				/>
			),
			title: 'Scripting Magic',
			description:
				'We start with your story, crafting the perfect narrative for your music.',
		},
		{
			icon: (
				<MdOutlinePhotoCamera
					color={theme.colors.mainYellow}
					size={32}
				/>
			),
			title: 'Visual Crafting',
			description:
				'Our artists then create captivating visuals that complement your lyrics.',
		},
		{
			icon: (
				<LuCircleDashed
					color={theme.colors.mainYellow}
					size={32}
				/>
			),
			title: 'Final Touches',
			description:
				'We add the finishing touches, ensuring every beat is visually impactful.',
		},
	];

	const settings = {
		dots: true, // Disable dot indicators if not needed
		infinite: true, // Enable infinite looping
		slidesToShow: 5, // Show 5 images at a time
		slidesToScroll: 1, // Scroll 1 image at a time
		arrows: true, // Disable arrows
		swipe: true,
		autoplay: true, // Enable auto-play feature
		autoplaySpeed: 1000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3, // Adjust for smaller screens
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2, // Adjust for the smallest screens
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<WholeContainer>
			<TopSectionContainer>
				<ImagePage
					src={FirstImage}
					alt='Our Creative Team'
					height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
				/>
			</TopSectionContainer>
			<TextContainer>
				<PortfolioTitle>
					3D Video <WhiteTextSpan>Production</WhiteTextSpan>
				</PortfolioTitle>
				<YellowLine />
				<ServiceText>
					Davix Studio: Shaping 1353+ lyrical masterpieces, the premier choice
					for dynamic video production. Elevate your music with our unparalleled
					creativity and expertise.
				</ServiceText>
				<NewItemLink to='/contact'>
					<Button
						width={'150px'}
						height={'50px'}
						onClick={() => {}}
						backgroundcolor={'#38D3E5'}
						borderRadius='50px'
					>
						Contact Us
					</Button>
				</NewItemLink>
			</TextContainer>

			<VideoWrapper>
				<ReactPlayer
					url={`https://www.youtube.com/watch?v=axoUwv3O29A`}
					playing
					preload='auto'
					width='100%'
					height='100%'
					config={{
						youtube: {
							playerVars: {
								vq: 'hd1080',
							},
						},
					}}
				/>
			</VideoWrapper>
			<BeageContainer />
			<OurVisionContainer>
				<Particles
					id='tsparticlesvision'
					init={particlesInit}
					loaded={particlesLoaded}
					options={{
						fpsLimit: 30,
						interactivity: {
							detectsOn: 'parent', // This line is crucial
							events: {
								// Define any interactive events here, like onHover, onClick, etc.
							},
						},
						fullScreen: { enable: false, zIndex: 0 },
						// The rest of your particles configuration...
						particles: {
							color: {
								value: '#fff',
							},
							links: {
								enable: false,
							},
							collisions: {
								enable: false,
							},
							move: {
								direction: 'none',
								enable: true,
								outModes: {
									default: 'bounce',
								},
								random: false,
								speed: 0.5,
								straight: false,
							},
							number: {
								density: {
									enable: true,
									area: isMobile ? 400 : 800, // Adjust based on your responsiveness needs
								},
								value: 20,
							},
							opacity: {
								value: 0.4,
								animation: {
									enable: true,
									speed: 0.3,
									minimumValue: 0,
									sync: true,
								},
							},
							shape: {
								type: 'circle',
							},
							size: {
								value: { min: 1, max: 3 },
							},
						},
					}}
				/>
				<OurVisionTitle>Our Vision</OurVisionTitle>
				<OurVisionText>
					We craft stunning lyric videos to bring your songs to life, ensuring
					your message connects with listeners.
				</OurVisionText>
				<Flexbox>
					<Image src={OurVision}></Image>
					<FlexColumnBox>
						<FlexBoxTitle>How We Do It</FlexBoxTitle>
						<FlexBoxText>A seamless process for perfect results.</FlexBoxText>
						<NewItemLink to='/contact'>
							<Button
								width={'150px'}
								height={'50px'}
								onClick={() => {}}
								backgroundcolor={'#38D3E5'}
								borderRadius='50px'
							>
								Contact Us
							</Button>
						</NewItemLink>
					</FlexColumnBox>
				</Flexbox>{' '}
				<FlexBoxCards>
					{cards.map((card) => (
						<CartItem
							icon={card.icon}
							title={card.title}
							description={card.description}
							width={'380px'}
						/>
					))}
				</FlexBoxCards>
			</OurVisionContainer>
			<WorkedWIthContainer>
				<TextWidth>
					<Title>
						Names We Worked <TitleSpan>With</TitleSpan>
					</Title>
				</TextWidth>
			</WorkedWIthContainer>
			<LogosContainerWrapper>
				<LogosContainer>
					<Slider {...settings}>
						<ImageIcons
							src={image1}
							alt='Image 1'
						/>
						<ImageIcons
							src={image2}
							alt='Image 2'
						/>
						<ImageIcons
							src={image3}
							alt='Image 3'
						/>
						<ImageIcons
							src={image4}
							alt='Image 4'
						/>
						<ImageIcons
							src={image5}
							alt='Image 5'
						/>
						<ImageIcons
							src={image6}
							alt='Image 6'
						/>
						<ImageIcons
							src={image7}
							alt='Image 7'
						/>
					</Slider>
				</LogosContainer>
			</LogosContainerWrapper>
		</WholeContainer>
	);
};

export default AnimationServices;
