import { useEffect, useState } from 'react';

export const useCounter = (target, duration, startAnimation) => {
	const [count, setCount] = useState(0);

	useEffect(() => {
		if (!startAnimation) return;

		let start = 0;
		const end = parseInt(target, 10);
		if (start === end) return;

		const totalIncrements = Math.ceil((duration / 1000) * 60);
		const increment = (end - start) / totalIncrements;

		const timer = setInterval(() => {
			start += increment;
			if (start >= end) {
				clearInterval(timer);
				start = end; // Ensure it ends on the exact end value
			}
			setCount(Math.ceil(start));
		}, 1000 / 60);

		return () => clearInterval(timer);
	}, [target, duration, startAnimation]);

	// Format the count as a string with commas
	return count.toLocaleString();
};
