import { React } from 'react';
import { ButtonContainer } from './style';
import theme from 'theme';

function Button({
	children,
	onClick,
	width,
	height,
	backgroundcolor = theme.colors.mainYellow,
	color = theme.colors.white,
	activeColor = theme.colors.mainYellow,
	active = false,
	borderRadius = '12px',
	borderColor,
	activeColorFont,
}) {
	return (
		<ButtonContainer
			width={width}
			height={height}
			onClick={onClick}
			color={color}
			active={active}
			activeColor={activeColor}
			backgroundcolor={backgroundcolor}
			borderRadius={borderRadius}
			borderColor={borderColor}
			activeColorFont={activeColorFont}
		>
			{children}
		</ButtonContainer>
	);
}
export default Button;
