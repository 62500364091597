import React, { useRef } from 'react';
import {
	WholeContainer,
	FlexBox,
	TextContainer,
	ContactUsTitle,
	YellowLine,
	ContactUsText,
	FormBorder,
	InputField,
	TextAreaField,
	Div,
	InputLabel,
	BlackBackGround,
	ImageContainer,
	ContentContainer,
	ContactUsWords,
	BeageContainer,
} from './style';
import Button from 'components/Button/Button';
import emailjs from 'emailjs-com';
import {
	FirstImagePage,
	MiddleSectionContainer,
	SecondImagePage,
	TopSectionContainer,
	VideoContainer,
} from 'components/AboutUs/style';
import FirstImage from 'assets/img/aboutUsPerson.png';
import aboutUsTeam from 'assets/img/aboutUsTeam.png';

function ContactUs() {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_EMAILJS_SERVICE_ID,
				process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
				form.current,
				process.env.REACT_APP_EMAILJS_USER_ID
			)
			.then(
				(result) => {
					form.current.reset(); // Reset the form after successful submission
				},
				(error) => {
					alert('Failed to send the message, please try again.');
				}
			);
	};
	return (
		<WholeContainer id='home'>
			<TopSectionContainer>
				<ImageContainer
					height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
				>
					<FirstImagePage
						src={FirstImage}
						alt='Our Creative Team'
						height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
					/>
				</ImageContainer>

				<BlackBackGround
					height={window.innerWidth < 768 ? 'auto' : window.innerHeight}
				></BlackBackGround>
			</TopSectionContainer>

			<ContentContainer>
				<TextContainer>
					<ContactUsTitle>For More Details </ContactUsTitle>
					<ContactUsWords>Contact Us!</ContactUsWords>
					<YellowLine />
					<ContactUsText>
						Send our team a quick message with your question, project ideas and
						we’ll get back to you in less then 24 hours. We’re pretty responsive
						and will try to respond in a few hours{' '}
					</ContactUsText>
				</TextContainer>
				<FormBorder
					ref={form}
					onSubmit={sendEmail}
				>
					<Div>
						<InputLabel>Name</InputLabel>
						<InputField
							type='text'
							name='user_name'
							placeholder='ex. John Doe'
						/>
					</Div>
					<Div>
						<InputLabel>Phone</InputLabel>
						<InputField
							type='tel'
							name='user_phone'
							placeholder='+1 123 456 7890'
						/>
					</Div>
					<Div>
						<InputLabel>Email</InputLabel>
						<InputField
							type='email'
							name='user_email'
							placeholder='JohnDoe@example.com'
						/>
					</Div>
					<Div>
						<InputLabel>Description</InputLabel>
						<TextAreaField
							name='message'
							placeholder="I will need a music/lyric/3D animated video. This is the link to my song. I'm available in 12:00 pm 'time zone'. Let's connect"
						></TextAreaField>
					</Div>
					<Button
						width={'100%'}
						height={'50px'}
					>
						Send Message
					</Button>
				</FormBorder>
			</ContentContainer>
			<BeageContainer />
		</WholeContainer>
	);
}

export default ContactUs;
