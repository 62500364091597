import styled from 'styled-components';

export const WholeContainer = styled.section`
	display: flex;
	gap: 0.5rem;
	width: 100%;
	background-color: #fff9ef;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-width: 768px) {
		flex-direction: column;
		display: flex;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
		align-items: center;
		justify-content: center;
	}
`;

export const FlexBox = styled.div`
	display: flex;
	flex-direction: row;
	gap: 50px;
	margin-left: -40px;
	margin-right: 180px;
	margin-top: 110px;
	padding-bottom: 40px;
	align-items: center;
	width: 80%;
	justify-content: space-between;
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
		width: 100%;
		align-items: left;
	}
`;

export const TextContainer = styled.div`
	justify-content: start;
	max-width: 800px;
	@media (max-width: 768px) {
		max-width: 100%;
	}
`;

export const ContactUsTitle = styled.h1`
	font-size: 48px;
	font-weight: ${(props) => props.theme.fontWeight.normal};
	color: ${(props) => props.theme.colors.grayTextColor};
	margin-bottom: 20px;
	@media (max-width: 768px) {
		font-size: 28px;
		margin-bottom: 20px;
		width: 90%;
	}
`;
export const ContactUsWords = styled.h1`
	font-size: 72px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	color: ${(props) => props.theme.colors.grayTextColor};
	margin-bottom: 20px;
	@media (max-width: 768px) {
		font-size: 28px;
		margin-bottom: 20px;
		width: 90%;
	}
`;

export const ContactUsText = styled.p`
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0.18px;
	color: ${(props) => props.theme.colors.grayTextColor};
	word-wrap: break-word;
	line-height: 23px;
	width: 500px;
	@media (max-width: 768px) {
		font-size: 16px;
		width: auto;
	}
`;

export const YellowLine = styled.div`
	width: 595px;
	height: 8px; // Adjust the height to control the thickness of the line
	margin: 20px 0 20px 0;
	background-color: ${(props) =>
		props.theme.colors.mainYellow}; // Set the line color to yellow
	@media (max-width: 768px) {
		width: 253px;
	}
`;

export const FormBorder = styled.form`
	width: 607px;
	padding: 20px; // Added padding inside the form for spacing
	display: flex;
	flex-direction: column;
	gap: 20px; // Added gap for spacing between form elements
	align-items: center;
	justify-content: flex-start; // Start aligning items from the top
	border: 2px solid white;
	box-shadow: 4px 4px 15px -5px rgba(255, 195, 33, 0.2);
	border-radius: 28px;
	@media (max-width: 768px) {
		width: 95%;
	}
`;

export const InputField = styled.input`
	padding: 15px;
	margin-bottom: 10px; // Consistent margin for all fields
	border: 1px solid #ccc;
	border-radius: 5px;
	&:focus {
		border-color: ${(props) => props.theme.colors.mainYellow};
		outline: none; // Removes the default focus outline
		box-shadow: 0 0 5px ${(props) => props.theme.colors.mainYellow};
	}
`;

export const TextAreaField = styled.textarea`
	padding: 15px;
	margin-bottom: 10px; // Consistent margin for bottom
	border: 1px solid #ccc;
	border-radius: 5px;
	height: 150px; // Larger height for descriptions
	&:focus {
		border-color: ${(props) => props.theme.colors.mainYellow};
		outline: none; // Removes the default focus outline
		box-shadow: 0 0 5px ${(props) => props.theme.colors.mainYellow};
	}
`;

export const Div = styled.div`
	width: 100%; // Ensure div takes full width of its parent
	display: flex;
	flex-direction: column;
`;

export const InputLabel = styled.label`
	margin-bottom: 5px; // Space between label and input
	font-size: 18px;
	font-weight: 500;
	color: ${(props) => props.theme.colors.white};
`;

export const BlackBackGround = styled.div`
	background-color: black;
	width: 60%;
	height: ${(props) => props.height}px;
`;

export const ImageContainer = styled.div`
	position: relative;
	width: 40%; // Adjust width as needed
	height: ${(props) => props.height}px; // Adjust height as needed
	object-fit: fill;
`;

export const ContentContainer = styled(FlexBox)`
	position: absolute;
	margin-top: 30px;
`;

export const BeageContainer = styled.div`
	background-color: #fff9ef;
	margin-bottom: -9%;
	height: 20vh;
	width: 100%;
`;
