import ReactPlayer from 'react-player';
import styled from 'styled-components';

export const WholeContainer = styled.section`
	display: flex;
	gap: 0.5rem;
	width: 100%;

	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media (max-width: 768px) {
		flex-direction: column;
		display: flex;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px;
		padding-right: 20px;
		align-items: center;
		justify-content: center;
	}
`;

export const ImageAboutUs = styled.img`
	width: 100%;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const AboutUsText = styled.p`
	font-size: 24px;
	font-weight: 300;
	letter-spacing: 0.18px;
	color: ${(props) => props.theme.colors.grayTextColor};
	@media (max-width: 768px) {
		font-size: 16px;
		width: auto;
	}
`;

export const TopSectionContainer = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: -1px;
`;

export const MiddleSectionContainer = styled.div`
	width: 100%;
	display: flex;
	margin-top: -7px;
`;

export const Video = styled(ReactPlayer)`
	object-fit: fill;
	height: 1000px;
	video {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover !important; // This can stretch the video to fill the container
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const FirstImagePage = styled.img`
	width: 100%;
	object-fit: cover;
	margin-right: 10px;
	height: ${(props) => props.height}px;
`;

export const SecondImagePage = styled.img`
	object-fit: cover;
	width: 72.3vw;
	height: ${(props) => props.height}px;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	width: 30%; // Adjust width as needed
	height: ${(props) => props.height}px; // Adjust height as needed
	object-fit: fill;
`;

export const VideoContainer = styled.div`
	position: relative;
	width: 70%;
	height: ${(props) => props.height}px; // Adjust based on your layout
`;

export const OverlayText = styled.div`
	position: absolute;
	top: 29%;
	right: 3vw;
	transform: translate(-50%, -50%);
	color: white;
	width: 120px;
	font-size: 12px; // Adjust font size as needed
	z-index: 2; // Ensure it's above the image/video
	text-align: right;

	@media (max-width: 1564px) {
		top: 35vh;
		right: 5vh;
	}
`;

export const ImageStrips = styled.img`
	width: 250px;
	height: 130px;
	position: absolute;

	top: 12%;
	right: 6vw;
	@media (max-width: 1564px) {
		top: 15vh;
		right: 12vh;
	}
`;

export const AboutUsTitle = styled.h3`
	font-size: 26px;
	font-weight: 400;
	color: ${(props) => props.theme.colors.grayTextColor};
	position: absolute;
	top: 47vh;
	left: 0vh;
	transform: rotate(-90deg); // Rotate the text 90 degrees
`;

export const BlackLineAboutUs = styled.div`
	width: 2px;
	height: 300px;
	background-color: ${(props) => props.theme.colors.grayTextColor};
	position: absolute;
	top: 33vh;
	left: 11vh;
	@media (max-width: 1564px) {
		height: 280px;
		top: 31vh;
		left: 12.6vh;
	}
`;

export const MainTitle = styled.h1`
	font-size: 64px;
	font-weight: 400;
	width: 250px;
	font-style: italic;
	position: absolute;
	top: 35vh;
	left: 14vh;
	line-height: 80px;
	@media (max-width: 1564px) {
		font-size: 48px;
		top: 36vh;
		left: 16vh;
		line-height: 70px;
		width: 220px;
	}
`;

export const BigNumber = styled.h1`
	font-size: 100px;
	font-weight: 700;
	color: ${(props) => props.theme.colors.white};
	position: absolute;
	top: 35vh;
	right: 58vw;
	@media (max-width: 1564px) {
		font-size: 90px;
		right: 59vw;
	}
`;

export const OrangeLine = styled.div`
	height: 8px;
	width: 300px;
	background-color: ${(props) => props.theme.colors.mainYellow};
	position: absolute;
	top: 48vh;
	right: 50vw;

	@media (max-width: 1564px) {
		top: 50vh;
		right: 49vw;
	}
`;

export const AboutUsFirstText = styled.p`
	font-size: 18px;
	color: ${(props) => props.theme.colors.white};
	position: absolute;
	top: 51vh;
	width: 450px;
	right: 42.3vw;

	@media (max-width: 1564px) {
		top: 53vh;
		right: 39vw;
	}
`;

export const CopyRights = styled.p`
	font-size: 16px;
	color: ${(props) => props.theme.colors.grayTextColor};
	font-weight: 700;
	position: absolute;
	top: 96vh;
	left: 1vw;
	@media (max-width: 1564px) {
		top: 94vh;
		left: 1.5vw;
	}
`;

export const MidleTextBox = styled.div`
	background-color: black;
	position: absolute;
	top: 60vh;
	opacity: 90%;
	left: 3vw;
	height: 30vh;
	width: 20vw;
`;

export const TitleMidleTextBox = styled.h1`
	color: ${(props) => props.theme.colors.white};
	font-size: 42px;
	font-weight: 500;
	top: 1vh;
	right: 2vw;
	position: absolute;
	@media (max-width: 1564px) {
		font-size: 32px;
		top: 1vh;
		right: 2vw;
	}
`;

export const OrangeBlackLine = styled.div`
	height: 8px;
	width: 240px;
	background-color: ${(props) => props.theme.colors.mainYellow};
	position: absolute;
	top: 7vh;
	right: 3vw;

	@media (max-width: 1564px) {
		top: 7vh;
		right: 3.5vw;
		width: 200px;
	}
`;

export const TextMidleTextBox = styled.p`
	color: ${(props) => props.theme.colors.white};
	font-size: 14px;
	position: absolute;
	top: 9vh;
	right: 2vw;
	width: 17vw;
	@media (max-width: 1564px) {
		font-size: 11px;
		top: 9vh;
		right: 1.5vw;
		width: 17vw;
	}
`;

export const ThirdImagePage = styled.img`
	width: 100%;
	margin-top: -8px;
	height: ${(props) => props.height}px;
`;

export const ThirdSectionContainer = styled.div`
	width: 100%;
	object-fit: fill;
`;

export const BottomColor = styled.div`
	margin-top: -8px;
	background-color: #fef9ef;
	height: 200px;
`;

export const ThirdImageFirstTextTitle = styled.h3`
	position: absolute;
	margin-top: -90vh;
	left: 5vw;
	font-size: 24px;
	background-color: black;
	color: white;
`;
export const ThirdImageFirstTextWrapper = styled.div`
	max-width: 250px;
`;
export const ThirdImageFirstTextDescription = styled.p`
	position: absolute;
	margin-top: -85vh;
	left: 6vw;
	font-size: 16px;
	color: black;
	word-wrap: break-word; // Break words when they overflow
	line-height: 23px;
	width: 420px;
`;

export const FourthImageFirstTextTitle = styled.h3`
	position: absolute;
	margin-top: -45vh;
	left: 16.3vw;
	font-size: 24px;
	background-color: black;
	color: white;
`;

export const FourthImageFirstTextDescription = styled.p`
	position: absolute;
	margin-top: -40vh;
	left: 12vw;
	text-align: right;
	font-size: 16px;
	color: black;
	word-wrap: break-word;
	line-height: 23px;
	width: 420px;
	@media (max-width: 1564px) {
		left: 10vw;
	}
`;

export const BigTextRight = styled.h1`
	font-size: 100px;
	font-weight: 700;
	color: ${(props) => props.theme.colors.white};
	position: absolute;
	margin-top: -70vh;
	right: 27vw;

	@media (max-width: 1564px) {
		right: 17vw;
	}
`;

export const WhiteLine = styled.div`
	height: 8px;
	width: 42%;
	background-color: ${(props) => props.theme.colors.white};
	position: absolute;
	margin-top: -55vh;
	right: 10vw;
`;

export const BigTextRightBottom = styled.h1`
	font-size: 100px;
	font-weight: 700;
	color: ${(props) => props.theme.colors.white};
	position: absolute;
	margin-top: -53vh;
	right: 5vw;
`;

export const SmallDescription = styled.p`
	font-size: 12px;
	color: ${(props) => props.theme.colors.white};
	position: absolute;
	margin-top: -35vh;
	right: 37vw;
	word-wrap: break-word;
	line-height: 23px;
	width: 370px;

	@media (max-width: 1564px) {
		right: 30vw;
	}
`;

export const ContactUsButton = styled.div`
	position: absolute;
	margin-top: -33vh;
	right: 8vw;
`;
