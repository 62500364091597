import styled from 'styled-components';

export const Item = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: left;
	gap: 0.8rem;
	max-width: ${(props) => props.width};
`;

export const Title = styled.h2`
	font-size: 24px;
	font-weight: ${(props) => props.theme.fontWeight.semibold};
	color: ${(props) => props.theme.colors.mainPageColorWhite};
	font-family: ${(props) => props.theme.fontFamily.montserrat};
`;

export const Description = styled.h2`
	font-size: 18px;
	font-weight: 300;
	color: ${(props) => props.theme.colors.mainPageColorWhite};
	font-family: ${(props) => props.theme.fontFamily.montserrat};
`;
