import React, { useCallback, useEffect } from 'react';
import Particles from 'react-tsparticles';
import {
	FooterContainer,
	FooterContentContainer,
	FooterBigTextContainer,
	FooterBigText,
	FooterSmallText,
	FooterBigContainer,
	FooterAllRightsText,
	FooterAllRightsContainer,
} from './style';
import { LogoImage } from 'components/Header/style';
import DavixRoundedLogo from 'assets/img/DavixRoundedLogo.png';
import { loadSlim } from 'tsparticles-slim';
import { useLocation } from 'react-router-dom';
function Footer() {
	const location = useLocation();
	const particlesInit = useCallback(async (engine) => {
		console.log(engine);
		// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		//await loadFull(engine);
		await loadSlim(engine);
	}, []);
	const particlesLoaded = useCallback(async (container) => {}, []);
	const isMobile = window.innerWidth < 768;
	useEffect(() => {
		// Target the canvas element directly
		const canvas = document.querySelector('#tsparticles canvas');
		if (canvas) {
			// Use setProperty to override styles with !important
			canvas.style.setProperty('position', 'absolute', 'important');
			canvas.style.setProperty('top', 'auto', 'important');
			canvas.style.setProperty('left', 'auto', 'important');
			canvas.style.setProperty('bottom', '0', 'important');
			canvas.style.setProperty('width', '100%', 'important');
			canvas.style.setProperty(
				'height',
				window.innerWidth < 768 ? '500px' : '600px',
				'important'
			);
			// Adjust '600px' as needed to match your FooterContainer height
		}
	}, []);

	const isPortfolioLink =
		(location.pathname === '/portfolio') | (location.pathname === '/aboutUs');
	return (
		<FooterContainer isPortfolioLink={isPortfolioLink}>
			<Particles
				id='tsparticles'
				init={particlesInit}
				loaded={particlesLoaded}
				options={{
					fpsLimit: 30,
					interactivity: {
						detectsOn: 'parent', // This line is crucial
						events: {
							// Define any interactive events here, like onHover, onClick, etc.
						},
					},
					fullScreen: { enable: false, zIndex: 0 },
					// The rest of your particles configuration...
					particles: {
						color: {
							value: '#fff',
						},
						links: {
							enable: false,
						},
						collisions: {
							enable: false,
						},
						move: {
							direction: 'none',
							enable: true,
							outModes: {
								default: 'bounce',
							},
							random: false,
							speed: 0.5,
							straight: false,
						},
						number: {
							density: {
								enable: true,
								area: isMobile ? 400 : 800, // Adjust based on your responsiveness needs
							},
							value: 20,
						},
						opacity: {
							value: 0.4,
							animation: {
								enable: true,
								speed: 0.3,
								minimumValue: 0,
								sync: true,
							},
						},
						shape: {
							type: 'circle',
						},
						size: {
							value: { min: 1, max: 3 },
						},
					},
				}}
			/>
			<FooterContentContainer>
				{/* Your footer content here */}
				{window.innerWidth < 768 ? (
					<>
						<FooterBigTextContainer>
							<FooterBigContainer>
								<FooterBigText>Ready Song?</FooterBigText>
								<FooterSmallText>
									Then contact us right now, so we can discuss exactly on how
									the end result will look.
								</FooterSmallText>
							</FooterBigContainer>
						</FooterBigTextContainer>
						<FooterBigTextContainer>
							<FooterBigContainer>
								<FooterBigText>contact@davixstudio.com</FooterBigText>
								<FooterSmallText>+1-885-319-5445</FooterSmallText>
							</FooterBigContainer>
						</FooterBigTextContainer>
						<FooterBigContainer
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<LogoImage
								src={DavixRoundedLogo}
								alt='Logo'
								width={'300px'}
								height={'300px'}
							/>
						</FooterBigContainer>
					</>
				) : (
					<>
						<FooterBigTextContainer>
							<FooterBigContainer>
								<FooterBigText>Ready Song?</FooterBigText>
								<FooterSmallText>
									Then contact us right now, so we can discuss exactly on how
									the end result will look.
								</FooterSmallText>
							</FooterBigContainer>
						</FooterBigTextContainer>
						<FooterBigContainer
							style={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								marginTop: '280px',
							}}
						>
							<LogoImage
								src={DavixRoundedLogo}
								alt='Logo'
								width={'300px'}
								height={'300px'}
							/>
							<FooterAllRightsContainer>
								<FooterAllRightsText>©2019-2024</FooterAllRightsText>
								<FooterAllRightsText>Davix Studio, LLC</FooterAllRightsText>
								<FooterAllRightsText>All rights reserved</FooterAllRightsText>
							</FooterAllRightsContainer>
						</FooterBigContainer>
						<FooterBigTextContainer>
							<FooterBigContainer
								style={{
									alignItems: 'end',
									flexDirection: 'column',
									display: 'flex',
								}}
							>
								<FooterBigText style={{ textAlign: 'right' }}>
									contact@davixstudio.com
								</FooterBigText>
								<FooterSmallText style={{ textAlign: 'right', width: '100%' }}>
									+1-885-319-5445
								</FooterSmallText>
							</FooterBigContainer>
						</FooterBigTextContainer>
					</>
				)}
			</FooterContentContainer>
		</FooterContainer>
	);
}

export default Footer;
