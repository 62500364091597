import { React, useContext, useEffect, useRef, useState } from 'react';
import Logo from 'assets/img/logo.png';
import LogoWhite from 'assets/img/logo_white.png';
import {
	StyledHeader,
	DesktopContainer,
	LogoLink,
	LogoImage,
	BigDiv,
	NewItemLink,
	MobileContainer,
	MobileMenuIcon,
	NewItem,
	MotionDivNewItemLogout,
	ServicesContainer,
	ServicesTitle,
	Divider,
	ServiceLinkContainer,
	ServiceDescription,
	ServiceTextContainer,
	ServicesTitle2,
	HeaderLine,
	ServiceTextContainer2,
	ServiceTextSeparator,
} from './style';
import Button from 'components/Button/Button';
import { MdMenu, MdHome, MdDesignServices } from 'react-icons/md';
import { BsFillCameraVideoFill } from 'react-icons/bs';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

function Header({ filled = true }) {
	const location = useLocation(); // Use the useLocation hook
	const mobileMenuRef = useRef(null);
	const servicesDropdownRef = useRef(null);
	const servicesTriggerRef = useRef(null);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
	const theme = useContext(ThemeContext);
	const [isServicesSubMenuOpen, setIsServicesSubMenuOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(true);
	const [position, setPosition] = useState(window.scrollY);
	const navigate = useNavigate();

	// Function to toggle mobile menu
	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	// Function to toggle services dropdown
	const toggleServicesDropdown = (e) => {
		setIsServicesDropdownOpen(!isServicesDropdownOpen);
		if (e) e.preventDefault(); // Prevent default link behavior
	};

	useEffect(() => {
		const handleScroll = () => {
			let moving = window.scrollY;
			setIsVisible(position > moving);
			setPosition(moving);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [position]);

	const isCurrentPathService =
		location.pathname === '/lyric-services' ||
		location.pathname === '/animation-services'; // Dependency array is empty to run only on mount and unmount // Empty dependency array ensures this runs only on mount and unmount

	const activeContactUs = location.pathname === '/contact';

	useEffect(() => {
		if (servicesDropdownRef.current && servicesTriggerRef.current) {
			const triggerRect = servicesTriggerRef.current.getBoundingClientRect();
			servicesDropdownRef.current.style.top = `${triggerRect.bottom}px`;
			servicesDropdownRef.current.style.left = `${triggerRect.left - 155}px`;
		}
	}, [isServicesDropdownOpen]);

	return (
		<StyledHeader
			style={{ top: isVisible ? '0' : '-100px', transition: 'top 0.3s' }}
			filled={filled}
		>
			<DesktopContainer>
				<LogoLink
					to={'/'}
					whileTap={{ scale: 0.6 }}
				>
					<LogoImage
						src={filled ? LogoWhite : Logo}
						alt='Logo'
						width={'60px'}
						height={'60px'}
					/>
					<HeaderLine />

					<NewItemLink to='/*'>Home</NewItemLink>
					<NewItemLink
						to=''
						onClick={(e) => toggleServicesDropdown(e)}
						ref={servicesTriggerRef}
						style={{
							cursor: 'pointer',
							position: 'relative',
							color:
								isServicesDropdownOpen || isCurrentPathService
									? theme.colors.mainYellow
									: theme.colors.mainPageColorHeaderWhite,
						}}
					>
						Services
					</NewItemLink>
				</LogoLink>
				<BigDiv>
					<NewItemLink to='/portfolio'>Portfolio</NewItemLink>
					<NewItemLink to='/aboutUs'>About Us</NewItemLink>
					<NewItemLink to='/contact'>
						<Button
							width={'150px'}
							height={'40px'}
							backgroundcolor={theme.colors.mainPageColorHeaderWhite}
							color={theme.colors.mainPageColorBlack}
							borderRadius={'55px'}
							active={activeContactUs}
							onClick={() => {}}
						>
							Contact Us
						</Button>
					</NewItemLink>
				</BigDiv>
			</DesktopContainer>

			<MobileContainer>
				<LogoLink
					to={'/'}
					whileTap={{ scale: 0.6 }}
				>
					<LogoImage
						src={Logo}
						alt='Logo'
						width={'38px'}
						height={'38px'}
					/>
				</LogoLink>
				<MobileMenuIcon onClick={toggleMobileMenu}>
					<MdMenu size={24} /> {/* Hamburger icon */}
				</MobileMenuIcon>

				{isMobileMenuOpen && (
					<MotionDivNewItemLogout
						ref={mobileMenuRef}
						initial={{ opacity: 0, y: -20 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -20 }}
						transition={{ duration: 0.5 }}
					>
						<NewItem
							to='/'
							onClick={toggleMobileMenu}
						>
							<MdHome />
							Home
						</NewItem>

						{/* Services item with toggle for submenu */}
						<NewItem
							as='div' // Render as div to prevent navigation
							onClick={() => setIsServicesSubMenuOpen(!isServicesSubMenuOpen)} // Toggle submenu
						>
							<MdDesignServices />
							Services
							{isServicesSubMenuOpen ? (
								<MdKeyboardArrowUp />
							) : (
								<MdKeyboardArrowDown />
							)}
						</NewItem>

						{/* Conditional rendering of submenu */}
						{isServicesSubMenuOpen && (
							<>
								<NewItem
									to='/lyric-services'
									onClick={toggleMobileMenu}
								>
									Lyric Video
								</NewItem>
								<NewItem
									to='/animation-services'
									onClick={toggleMobileMenu}
								>
									3D Animated Music Video
								</NewItem>
							</>
						)}

						<NewItem
							to='/portfolio'
							onClick={toggleMobileMenu}
						>
							<BsFillCameraVideoFill />
							Portfolio
						</NewItem>
						<NewItem
							to='/aboutUs'
							onClick={toggleMobileMenu}
						>
							<FaUsers />
							About Us
						</NewItem>
						<NewItem
							to='/contact'
							onClick={toggleMobileMenu}
						>
							<FaPhone />
							Contact Us
						</NewItem>
					</MotionDivNewItemLogout>
				)}
			</MobileContainer>
			{/* Mobile container and rest of your component remains unchanged */}
			{isServicesDropdownOpen && (
				<ServicesContainer
					ref={servicesDropdownRef}
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: -20 }}
					transition={{ duration: 0.5 }}
					style={{ position: 'absolute', top: '100px', width: '200px' }}
				>
					<ServiceTextContainer
						onClick={(e) => {
							navigate('/lyric-services');
							toggleServicesDropdown(e);
						}}
					>
						<ServicesTitle2>Lyric Video</ServicesTitle2>
					</ServiceTextContainer>
					<ServiceTextSeparator />
					<ServiceTextContainer2
						onClick={(e) => {
							navigate('/animation-services');
							toggleServicesDropdown(e);
						}}
					>
						<ServicesTitle2>3D Animated Music Video</ServicesTitle2>
					</ServiceTextContainer2>
				</ServicesContainer>
			)}
		</StyledHeader>
	);
}

export default Header;
